import React from 'react';
import './tableForLookup.css';
import Row from '../rowForLookup/rowForLookup.jsx';
import DatePicker from 'react-datepicker';
import Pagination from '../pagination/pagination.jsx';
import History from '../historyForLookup/historyForLookup.jsx';
import Edit from '../editForLookup/editForLookup.jsx';
import { trackPromise } from 'react-promise-tracker';

class PanTable extends React.Component {

    constructor() {
        super();
        this.state = {
            fullData: [],
            pageOfItems: [],
            editData: {},
            historyData : null,
            UpdatedOn: null,
            filtedData: [],
            filter: {}
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.onChangePage = this.onChangePage.bind(this);
        this.setEditData = this.setEditData.bind(this);
        this.setHistoryData = this.setHistoryData.bind(this);
    }

    async componentDidMount() {
        const idToken = JSON.parse(localStorage.getItem('okta-token-storage'));

        await trackPromise(
            fetch('/admin-api/lookups',{
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + idToken.accessToken.accessToken,
                    },
                })
                .then(res => res.json())
                .then(data => this.setState({
                    fullData: data.data
                }, () => console.log('Full data fetched! + data.data')))
        )
        this.convertTime();
        this.initFilter();
        this.filterRows();
    }

    convertTime(){
        let fullData = this.state.fullData;
        for(let ele of fullData) {
            if(ele.CreatedOn) ele.CreatedOn = new Date(ele.CreatedOn).toLocaleString('en-US', { timeZone: 'America/Los_Angeles' });
            if(ele.UpdatedOn) ele.UpdatedOn = new Date(ele.UpdatedOn).toLocaleString('en-US', { timeZone: 'America/Los_Angeles' });
        }
        this.setState({ fullData : fullData});
    }

    onChangePage(pageOfItems) {
        // update state with new page of items
        this.setState({ pageOfItems: pageOfItems });
    }

    setEditData(editData){
        this.setState({ editData: editData });
    }

    setHistoryData(historyData){
        this.setState({ historyData: historyData });
    }

    initFilter() {
        let filter = {};
        // let excludeColumn = ['LookupId','IsActive','CreatedBy', 'CreatedOn'];
        let dateColumn = ['UpdatedOn'];

        for (let key of Object.keys(this.state.fullData[0])) {
            if (!dateColumn.includes(key)) {
                filter[key] = { filterType: 'contains', filterText: '' };
            } else {
                filter[key] = { filterType: 'isBefore', filterText: '' };
            }
        }

        this.setState({ filter: filter });
    }

    updateDateChanged = (d) => {
        if(d){
            let newFilter = this.state.filter;
            newFilter["UpdatedOn"]["filterText"] = d;
            this.setState({
                UpdatedOn: d,
                filter: newFilter
            });
        } else {
            let newFilter = this.state.filter;
            newFilter["UpdatedOn"]["filterText"] = "";
            this.setState({
                UpdatedOn: null,
                filter: newFilter
            });        
        }
        this.filterRows();
    }

    handleInputChange(event) {
        let target = event.target;
        if (target.type === "text") {
            let filterIndex = target.getAttribute("filterindex");
            let newFilter = this.state.filter;
            let filterText = target.value.trim();
            let trueFalseColumn = ['ShowScore'];

            if (!trueFalseColumn.includes(filterIndex)) {
                newFilter[filterIndex]["filterText"] = filterText;
            } else {
                if (filterText.toUpperCase() === 'TRUE') {
                    newFilter[filterIndex]["filterText"] = '1';
                } else if (filterText.toUpperCase() === 'FALSE') {
                    newFilter[filterIndex]["filterText"] = '0';
                } else {
                    newFilter[filterIndex]["filterText"] = '';
                }
            }
            this.setState({
                filter: newFilter
            });
            this.filterRows();
        } else if (target.type === "radio") {
            let filterIndex = target.getAttribute("filterindex");
            let filterType = target.value;
            let newFilter = this.state.filter;
            newFilter[filterIndex]["filterType"] = filterType;
            this.setState({
                filter: newFilter
            });
            this.filterRows();
        }
    }

    filterRows() {
        let fullData = this.state.fullData;
        let filter = this.state.filter;

        let resRow = [];
        for (let row of fullData) {
            let flag = true;
            for (let [filterKey, filterValue] of Object.entries(filter)) {
                if (filterValue.filterText === '' && filterValue.filterType !== "isEmpty") continue;
                let unitText = row[filterKey];// text at position {row, filterKey}
                if (unitText == null) unitText = '';

                if (typeof unitText === 'number') {
                    unitText = unitText.toString();
                }
                unitText = unitText.toUpperCase();
                if (filterValue.filterType === 'contains') {
                    if (!unitText.includes(filterValue.filterText.toUpperCase())) {
                        flag = false;
                        break;
                    }
                } else if (filterValue.filterType === 'equals') {
                    if (unitText !== filterValue.filterText.toUpperCase()) {
                        flag = false;
                        break;
                    }
                } else if (filterValue.filterType === 'isBefore') {
                    let unitDate = new Date(unitText);
                    let filterDate = new Date(filterValue.filterText);

                    if (unitDate > filterDate) {
                        flag = false;
                        break;
                    }
                } else if (filterValue.filterType === 'isAfter') {
                    let unitDate = new Date(unitText);
                    let filterDate = new Date(filterValue.filterText);
                    if (unitDate < filterDate) {
                        flag = false;
                        break;
                    }
                }  else if (filterValue.filterType === 'isEmpty') {
                    if (unitText !== null && unitText.trim() !== '') {
                        flag = false;
                        break;
                    }
                }
            }

            if (flag) {
                resRow.push(row);
            }
        }
        this.setState({ filtedData: resRow });
    }

    render() {
        let pageOfItems = this.state.pageOfItems;
        let filter = this.state.filter;
        let filtedData = this.state.filtedData;
        let editData = this.state.editData;
        let historyData = this.state.historyData;


        let title = [], columnKey = [], dateColumn = [];
        if(Object.entries(filter).length !== 0){
            title = ['Exam Short Title', 'Comment', 'Level Up Course Code', 'Beacon Course Code', 'Pearson Vue Exam Code', 'Validity', 'Show Score', 'Last Updated'];
            columnKey = ['ShortTitle', 'Comment', 'LevelupCourseCode', 'BeaconCourseCode', 'PearsonVueExamCode','Validity', 'ShowScore', 'UpdatedOn'];
            dateColumn = ['UpdatedOn'];
        }
        return (
            <div className="panTable">
                <div className="panTableBody">
                    <History historyData={historyData}></History>
                    <Edit editData={editData}></Edit>
                    <div className="table-responsive">
                    <table className="table table-bordered table-striped">
                        <thead>
                            <tr>
                            {columnKey.map((ele, index) =>
                                !dateColumn.includes(ele) ?
                                    <th scope="col" key={index}>
                                        <div className="input-group">
                                            <input type="text" className="form-control" onChange={this.handleInputChange} filterindex={ele} />
                                            <div className="input-group-append">
                                                <button className="btn btn-outline-secondary dropdown-toggle" type="button"
                                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></button>
                                                <div className="dropdown-menu">
                                                    <div className="form-check dropdown-item">
                                                        <input className="form-check-input" type="radio" id={ele + 'radio1'} value="contains" checked={filter[ele].filterType === "contains"} onChange={this.handleInputChange} filterindex={ele} />
                                                        <label className="form-check-label" htmlFor={ele + 'radio1'}>
                                                            Contains
                                                        </label>
                                                    </div>
                                                    <div className="form-check dropdown-item">
                                                        <input className="form-check-input" type="radio" id={ele + 'radio2'} value="equals" checked={filter[ele].filterType === "equals"} onChange={this.handleInputChange} filterindex={ele} />
                                                        <label className="form-check-label" htmlFor={ele + 'radio2'}>
                                                            Equals to
                                                        </label>
                                                    </div>
                                                    <div className="form-check dropdown-item">
                                                        <input className="form-check-input" type="radio" id={ele + 'radio3'} value="isEmpty" checked={filter[ele].filterType === "isEmpty"} onChange={this.handleInputChange} filterindex={ele} />
                                                        <label className="form-check-label" htmlFor={ele + 'radio3'}>
                                                            Is Empty
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </th>
                                    :
                                    <th scope="col" key={index}>
                                    <div className="date-input-group">

                                        <DatePicker className="form-control"
                                            selected={this.state[ele]}
                                            onChange={this.updateDateChanged}
                                            dateFormat="MM/dd/yyyy"
                                            isClearable
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                        />
                                        <div className="input-group-append">
                                            <button className="btn btn-outline-secondary dropdown-toggle" type="button"
                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></button>
                                            <div className="dropdown-menu">
                                                <div className="form-check dropdown-item">
                                                    <input className="form-check-input" type="radio" id={ele + 'radio1'} value="isBefore" checked={filter[ele].filterType === "isBefore"} onChange={this.handleInputChange} filterindex={ele} />
                                                    <label className="form-check-label" htmlFor={ele + 'radio1'}>
                                                        Is Before
                                                </label>
                                                </div>
                                                <div className="form-check dropdown-item">
                                                    <input className="form-check-input" type="radio" id={ele + 'radio2'} value="isAfter" checked={filter[ele].filterType === "isAfter"} onChange={this.handleInputChange} filterindex={ele} />
                                                    <label className="form-check-label" htmlFor={ele + 'radio2'}>
                                                        Is After
                                                    </label>
                                                </div>
                                                <div className="form-check dropdown-item">
                                                    <input className="form-check-input" type="radio" id={ele + 'radio3'} value="isEmpty" checked={filter[ele].filterType === "isEmpty"} onChange={this.handleInputChange} filterindex={ele} />
                                                    <label className="form-check-label" htmlFor={ele + 'radio3'}>
                                                        Is Empty
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </th>
                                )}
                                <th scope="col"></th>
                            </tr>
                            <tr>
                                {title.map((ele, index) =>
                                    <th scope="col" key={index} className="tableCol">{ele}</th>
                                )}
                                <th scope="col" className="tableCol">Actions</th>
                            </tr>
                        </thead>
                        <tbody className="table-body">
                            {pageOfItems.map((ele, index) =>
                                <Row key={index} rowData={ele} setEditData={this.setEditData} setHistoryData={this.setHistoryData}/>
                            )}
                        </tbody>
                    </table>
                    </div>
                </div>
                <Pagination className="pagination" items={filtedData} onChangePage={this.onChangePage}/>
            </div>
        )
    }

}

export default PanTable;
