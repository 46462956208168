import React from 'react';
import './editForLookup.css'
import { trackPromise } from 'react-promise-tracker';
import { toast } from "react-toastify";

class Edit extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            fieldset1 : false,
            fieldset2 : false,
            fieldset3 : false,
            LookupId: null,
            ShortTitle: "",
            Comment: "",
            LevelupCourseCode: "",
            BeaconCourseCode: "",
            PearsonVueExamCode: "",
            Validity: "",
            ShowScore: "",
            IsActive: "",
            CreatedBy: null,
            CreatedOn: null,
            commentsLength : 0,
            buttonStatus: false,
        }

        this.addModal = React.createRef();
        this.ShortTitle = React.createRef();
        this.LevelupCourseCode = React.createRef();
        this.BeaconCourseCode = React.createRef();
        this.PearsonVueExamCode = React.createRef();
        this.Validity = React.createRef();
        this.Comment = React.createRef();
        this.refreshPage = this.refreshPage.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        // console.log('Render Examlookup Edit');

        this.clearValidation();
        let obj = nextProps.editData;
        if (Object.keys(obj).length !== 0) {
            this.setState({
                fieldset1 : obj.LevelupCourseCode ? true : false,
                fieldset2 : obj.BeaconCourseCode ? true : false,
                fieldset3 : obj.PearsonVueExamCode ? true : false,
                LookupId: obj.LookupId,
                ShortTitle: this.handleNull(obj.ShortTitle),
                Comment: this.handleNull(obj.Comment),
                LevelupCourseCode: this.handleNull(obj.LevelupCourseCode),
                BeaconCourseCode: this.handleNull(obj.BeaconCourseCode),
                PearsonVueExamCode: this.handleNull(obj.PearsonVueExamCode),
                Validity: this.handleNull(obj.Validity),
                ShowScore: obj.ShowScore === 1 ? true : false,
                IsActive: obj.IsActive === 1 ? true : false,
                CreatedBy: obj.CreatedBy,
                CreatedOn: obj.CreatedOn,
                commentsLength : this.handleNull(obj.Comment).length,
                buttonStatus: false,
            })
        }
    }

    handleNull(val){
        if(val === null){
            return ""
        } else {
            return val
        }
    }

    clearValidation(){
        this.ShortTitle.current.classList.remove("is-invalid");
        this.ShortTitle.current.nextElementSibling.innerHTML = 'Please provide valid input.';
        // this.Validity.current.classList.remove("is-invalid");
        this.LevelupCourseCode.current.classList.remove("is-invalid");
        this.BeaconCourseCode.current.classList.remove("is-invalid");
        this.PearsonVueExamCode.current.classList.remove("is-invalid");
        this.PearsonVueExamCode.current.nextElementSibling.innerHTML = 'Please provide valid input.';
        this.Comment.current.classList.remove("is-invalid");
    }

    validateForm(formData){
        let [flag1, flag2, flag3, flag4, flag5] = [true, true, true, true, true];
        if(formData.ShortTitle === ""){
            this.ShortTitle.current.classList.add("is-invalid");
            flag1 = false;
        }

        // if(formData.Validity === ""){
        //     this.Validity.current.classList.add("is-invalid");
        //     flag2 = false;
        // }

        if(this.state.fieldset1 && formData.LevelupCourseCode === ""){
            this.LevelupCourseCode.current.classList.add("is-invalid");
            flag3 = false;
        }

        if(this.state.fieldset2 && formData.BeaconCourseCode === ""){
            this.BeaconCourseCode.current.classList.add("is-invalid");
            flag4 = false;
        }

        if(this.state.fieldset3 && formData.PearsonVueExamCode === ""){
            this.PearsonVueExamCode.current.classList.add("is-invalid");
            flag5 = false;
        }
        return flag1 && flag2 && flag3 && flag4 && flag5;
    }

    refreshPage() {
        window.location.reload(false);
    }

    async handleSubmit(event) {
        event.preventDefault();

        const idToken = JSON.parse(localStorage.getItem('okta-token-storage'));

        const data = new FormData(event.target);
        let jsonData =  {
            "LookupId" : null,
            "ShortTitle": null,
            "Comment": null,
            "LevelupCourseCode": null,
            "BeaconCourseCode": null,
            "PearsonVueExamCode": null,
            "Validity": null,
            "ShowScore": 0,
            "IsActive": 0,
            "CreatedBy": null,
            "SourceApplication": "Admin_UI",
            "UpdateBy" : idToken.idToken.claims.email
        };
        for (var [key, value] of data.entries()) {
            jsonData[key] = value.trim();
        }
        jsonData.LookupId = this.state.LookupId;

        this.clearValidation();

        const body = {
            "old" : this.props.editData,
            "new" : jsonData
        };

        if(this.validateForm(jsonData)){
            this.setState({
                buttonStatus: true
            })
            await trackPromise(
                fetch('/admin-api/lookup', {
                    method: 'PUT',
                    body: JSON.stringify(body),
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + idToken.accessToken.accessToken,
                    }
                }).then(response => response.json())
                .then(async (data) => {
                    if(!data.success) {
                        toast.error('Error!',{
                            position: "top-right",
                            autoClose: 3000,
                            progress: undefined,
                        });
                        if(data.message === "Invalid_pv_code"){
                            this.PearsonVueExamCode.current.classList.add("is-invalid");
                            this.PearsonVueExamCode.current.nextElementSibling.innerHTML = 'Dupicate Exam Code found. Please try another one.';
                        }
                        if(data.message === "Invalid_ShortTitle_Comment"){
                            this.ShortTitle.current.classList.add("is-invalid");
                            this.ShortTitle.current.nextElementSibling.innerHTML = 'Duplicate Short Title and Comment combo found. Please try another one';
                            this.Comment.current.classList.add("is-invalid");
                        }
                        this.setState({buttonStatus : false});
                        return;
                    }

                    // toast.success('Operation Success!',{
                    //     position: "top-right",
                    //     autoClose: 1500,
                    //     progress: undefined,
                    // });
                    // await new Promise(resolve => setTimeout(resolve, 1500));
                    window.location.reload(false);                    
                })
                .catch((error) => {
                    console.error('Error:', error);
                    toast.error('Error occured on Server!',{
                        position: "top-right",
                        autoClose: 3000,
                        progress: undefined,
                    });
                    this.setState({buttonStatus : false});
                })
            )
        }
    }

    fieldChange(fieldId, event) {
        if (event !== undefined) {
            if (fieldId === "fieldset1") {
                this.setState({
                    fieldset1: event.target.checked
                })
            } else if (fieldId === "fieldset2") {
                this.setState({
                    fieldset2: event.target.checked
                })
            } else if (fieldId === "fieldset3") {
                this.setState({
                    fieldset3: event.target.checked
                })
            } else if (fieldId === "BeaconCourseCode") {
                this.setState({
                    BeaconCourseCode: event.target.value
                })
            } else if (fieldId === "LevelupCourseCode") {
                this.setState({
                    LevelupCourseCode: event.target.value
                })
            } else if (fieldId === "PearsonVueExamCode") {
                this.setState({
                    PearsonVueExamCode: event.target.value
                })
            } else if (fieldId === "ShowScore") {
                this.setState({
                    ShowScore: event.target.checked ? 1 : 0
                })
            }else if (fieldId === "IsActive") {
                this.setState({
                    IsActive: event.target.checked ? 1 : 0
                })
            } else if (fieldId === "ShortTitle") {
                this.setState({
                    ShortTitle: event.target.value
                })
            } else if (fieldId === "Validity") {
                this.setState({
                    Validity: event.target.value
                })
            } else if (fieldId === "Comment") {
                const commentVal = event.target.value;
                const commentLength = commentVal.length;

                if(commentLength <= 255){
                    this.setState({
                        Comment: commentVal,
                        commentsLength : commentLength
                    })
                }
            }
        }
    }

    render() {
        const {fieldset1,fieldset2, fieldset3, ShortTitle, Comment, LevelupCourseCode, BeaconCourseCode, PearsonVueExamCode, Validity, ShowScore, IsActive, commentsLength, buttonStatus} = this.state;

        return (
                <div className="modal fade" id="edit" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" ref={this.addModal}>
                <div className="modal-dialog modal-xl modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLongTitle">Make changes to Exam Lookup table</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <form className="modal-body" onSubmit={this.handleSubmit} autoComplete="off">
                            <div className="form-group formUnit-shortTitle">
                                <label htmlFor="ShortTitle_edit">Exam Short Title</label>
                                <input type="text" className="form-control" name="ShortTitle" id="ShortTitle_edit" maxLength="100" value={ShortTitle} onChange={this.fieldChange.bind(this, "ShortTitle")} ref={this.ShortTitle}/>
                                <div className="invalid-feedback">
                                    Please provide valid input.
                                </div>
                            </div>
                            <hr/>
                            <div className="form-check formUnit-lvCode">
                                <input className="form-check-input" type="checkbox" name="fieldset1" value='1' checked={fieldset1} id="fieldset1_edit" onChange={this.fieldChange.bind(this, "fieldset1")} />
                                <label className="form-check-label" htmlFor="fieldset1_edit">
                                    Level Up Course Code
                                </label>
                                <fieldset disabled={fieldset1 ? "" : "disable"}>
                                    <input type="text" className="form-control" name="LevelupCourseCode" maxLength="100" value={LevelupCourseCode} onChange={this.fieldChange.bind(this, "LevelupCourseCode")}  ref={this.LevelupCourseCode}/>
                                    <div className="invalid-feedback">
                                        Please provide valid input.
                                    </div>
                                </fieldset>
                            </div>
                            <hr />
                            <div className="form-check formUnit-beCode">
                                <input className="form-check-input" type="checkbox" name="fieldset2" value="1" checked={fieldset2} id="fieldset2_edit" onChange={this.fieldChange.bind(this, "fieldset2")} />
                                <label className="form-check-label" htmlFor="fieldset2_edit">
                                    Beacon Course Code
                                </label>
                                <fieldset disabled={fieldset2 ? "" : "disable"}>
                                    <input type="text" className="form-control" name="BeaconCourseCode" maxLength="100" value={BeaconCourseCode} onChange={this.fieldChange.bind(this, "BeaconCourseCode")} ref={this.BeaconCourseCode}/>
                                    <div className="invalid-feedback">
                                        Please provide valid input.
                                    </div>
                                </fieldset>
                            </div>
                            <hr />
                            <div className="form-check formUnit-pvCode">
                                <input className="form-check-input" type="checkbox" name="fieldset3" value="1" checked={fieldset3} id="fieldset3_edit" onChange={this.fieldChange.bind(this, "fieldset3")} />
                                <label className="form-check-label" htmlFor="fieldset3_edit">
                                    Pearson Vue Exam Code
                                </label>
                                <fieldset disabled={fieldset3 ? "" : "disable"}>
                                    <input type="text" className="form-control" name="PearsonVueExamCode" maxLength="255" value={PearsonVueExamCode} onChange={this.fieldChange.bind(this, "PearsonVueExamCode")} ref={this.PearsonVueExamCode}/>
                                    <div className="invalid-feedback">
                                        Please provide valid input.
                                    </div>
                                </fieldset>
                            </div>
                            <hr/>
                            <div className="form-row formUnit-Va">
                                <div className="validity-col form-group col">
                                    <label htmlFor="validity">Validity </label> 
                                    <input className="form-control" type="number" list="Validity_edit" min="0" max="24" name="Validity" value={Validity} onChange={this.fieldChange.bind(this, "Validity")}  ref={this.Validity}/>
                                    <datalist id="Validity_edit">
                                        <option value="12">12</option>
                                        <option value="24">24</option>
                                    </datalist>

                                    <div className="invalid-feedback">
                                        Please provide valid input.
                                    </div>
                                </div>
                                <div className="form-group col check">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" name="ShowScore" id="Show_Score_edit" value="1" checked={ShowScore} onChange={this.fieldChange.bind(this, "ShowScore")}/>
                                        <label className="form-check-label" htmlFor="Show_Score_edit">Show Score</label>
                                    </div>
                                </div>
                                <div className="form-group col check">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" name="IsActive" id="Is_Active_edit" value="1" checked={IsActive} onChange={this.fieldChange.bind(this, "IsActive")}/>
                                        <label className="form-check-label" htmlFor="Is_Active_edit">Is Active</label>
                                    </div>
                                </div>
                            </div>
                            <div className="input-group comment">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">Comment</span>
                                </div>
                                <textarea className="form-control" name="Comment" aria-label="Comment" value={Comment} onChange={this.fieldChange.bind(this, "Comment")} ref={this.Comment}></textarea>
                                <div className="input-group-append">
                                    <span className="input-group-text">{commentsLength}/255</span>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-outline-danger" data-dismiss="modal" disabled={buttonStatus}>Cancel</button>
                                <input type="submit" value="Save to Change" className="btn btn-outline-primary" disabled={buttonStatus}></input>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

export default Edit;